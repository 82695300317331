
import { Component, Vue, } from 'vue-property-decorator'
import HeaderClient from './HeaderClient.vue'
import { HistoryProgressInterface, } from '@/store/types/History/History'
import ItemHistoryComponent from '@/components/ItemHistory/Index.vue'
import ControlDetail from '@/views/Startup/Project/ControlDetail.vue'
import TableEmptyState from '@/components/TableEmptyState/Index.vue'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    HeaderClient,
    ItemHistoryComponent,
    ControlDetail,
    TableEmptyState,
    OverlayBackground
  },
  head: {
    script: [{ type: 'application/javascript', src: '../js/analytics.js', },],
  },
})
export default class HistoryClient extends Vue {
  private Business = this.$store.state.BusinessModule.BusinessState.businessSelected
  private historyProgress: HistoryProgressInterface[] = []
  private showView = true
  private idControlDetail = 0
  private loading = false;
  
  private mounted () {
    this.getFrameworks()
    this.getHistory()
  }

  private goToInitial () {
    this.showView = true
  }

  private getFrameworks () {
    this.loading = true
    this.$store
      .dispatch('PolimorficModule/getFrameworks')
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  private getHistory () {    
    this.loading = true
    this.$store
      .dispatch('HistoryModule/getHistory', this.Business.id)
      .then((data) => {
        data.data.snapshots.forEach((element: any, index: any) => {
          index === 0 ? (element.open = true) : (element.open = false)
        })

        this.historyProgress = data.data.snapshots
        
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  private backToHistory () {
    this.showView = !this.showView
  }
}
